<template>
  <div class="logos">
    <img
      class="logos__icon"
      v-if="name == 'accenture'"
      src="@/assets/logos/accenture.png"
    />
    <img
      class="logos__icon"
      v-else-if="name == 'autozone'"
      src="@/assets/logos/autozone.png"
    />
    <img
      class="logos__icon"
      v-else-if="name == 'capitecbank'"
      src="@/assets/logos/capitecbank.png"
    />
    <img
      class="logos__icon"
      v-else-if="name == 'disney'"
      src="@/assets/logos/disney.png"
    />
    <img
      class="logos__icon"
      v-else-if="name == 'engie'"
      src="@/assets/logos/engie.png"
    />
    <img
      class="logos__icon"
      v-else-if="name == 'garmin'"
      src="@/assets/logos/garmin.png"
    />
    <img
      class="logos__icon"
      v-else-if="name == 'gm'"
      src="@/assets/logos/gm.png"
    />
    <img
      class="logos__icon"
      v-else-if="name == 'havas'"
      src="@/assets/logos/havas.png"
    />
    <img
      class="logos__icon"
      v-else-if="name == 'humana'"
      src="@/assets/logos/humana.png"
    />
    <img
      class="logos__icon"
      v-else-if="name == 'hypermatic'"
      src="@/assets/logos/hypermatic.png"
    />
    <img
      class="logos__icon"
      v-else-if="name == 'indrive'"
      src="@/assets/logos/indrive.png"
    />
    <img
      class="logos__icon"
      v-else-if="name == 'jcrew'"
      src="@/assets/logos/jcrew.png"
    />
    <img
      class="logos__icon"
      v-else-if="name == 'kaspi'"
      src="@/assets/logos/kaspi.png"
    />
    <img
      class="logos__icon"
      v-else-if="name == 'kcc'"
      src="@/assets/logos/kcc.png"
    />
    <img
      class="logos__icon"
      v-else-if="name == 'livibank'"
      src="@/assets/logos/livi.png"
    />
    <img
      class="logos__icon"
      v-else-if="name == 'lowes'"
      src="@/assets/logos/lowes.png"
    />
    <img
      class="logos__icon"
      v-else-if="name == 'manomano'"
      src="@/assets/logos/manomano.png"
    />
    <img
      class="logos__icon"
      v-else-if="name == 'montefiore-einstein'"
      src="@/assets/logos/montefiore-einstein.png"
    />
    <img
      class="logos__icon"
      v-else-if="name == 'oliveragency'"
      src="@/assets/logos/oliveragency.png"
    />
    <img
      class="logos__icon"
      v-else-if="name == 'ollyolly'"
      src="@/assets/logos/ollyolly.png"
    />
    <img
      class="logos__icon"
      v-else-if="name == 'revizto'"
      src="@/assets/logos/revizto.png"
    />
    <img
      class="logos__icon"
      v-else-if="name == 'shopware'"
      src="@/assets/logos/shopware.png"
    />
    <img
      class="logos__icon"
      v-else-if="name == 'spotify'"
      src="@/assets/logos/spotify.png"
    />
    <img
      class="logos__icon"
      v-else-if="name == 'springbokagency'"
      src="@/assets/logos/springbokagency.png"
    />
    <img
      class="logos__icon"
      v-else-if="name == 'square'"
      src="@/assets/logos/square.png"
    />
    <img
      class="logos__icon"
      v-else-if="name == 'strategiced'"
      src="@/assets/logos/strategiced.png"
    />
    <img
      class="logos__icon"
      v-else-if="name == 'svetzdravia'"
      src="@/assets/logos/svetzdravia.png"
    />
    <img
      class="logos__icon"
      v-else-if="name == 'ubs'"
      src="@/assets/logos/ubs.png"
    />
    <img
      class="logos__icon"
      v-else-if="name == 'vfc'"
      src="@/assets/logos/vfc.png"
    />
    <img
      class="logos__icon"
      v-else-if="name == 'volvo'"
      src="@/assets/logos/volvo.png"
    />
    <img
      class="logos__icon"
      v-else-if="name == 'walmart'"
      src="@/assets/logos/walmart.png"
    />
    <img
      class="logos__icon"
      v-else-if="name == 'workday'"
      src="@/assets/logos/workday.png"
    />
    <img
      class="logos__icon"
      v-else-if="name == 'wsgc'"
      src="@/assets/logos/wsgc.png"
    />
  </div>
</template>

<script>
export default {
  name: "Logos",
  props: {
    name: String
  }
};
</script>
<style lang="scss">
.logos__icon {
  max-height: 30px;
  width: auto;
  max-width: 100px;
  display: block;
  margin: auto;
}
</style>
