import Vue from "vue";
import VueRouter from "vue-router";
import Licenses from "../views/Licenses.vue";
import Subscriptions from "../views/Subscriptions.vue";
import Home from "../views/Home.vue";
import Firefoo from "../views/Firefoo.vue";
import Customers from "../views/Customers.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home
  },
  {
    path: "/enterprise/:orgId",
    name: "enterprise",
    component: Subscriptions
  },
  {
    path: "/enterprise/:orgId/:permalinkId",
    name: "licenses",
    component: Licenses
  },
  {
    path: "/firefoo",
    name: "firefoo",
    component: Firefoo
  },
  {
    path: "/firefoo/:licenseKey",
    name: "firefooKey",
    component: Firefoo
  },
  {
    path: "/customers",
    name: "customers",
    component: Customers
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
