<template>
  <div class="calendar">
    <vue-calendar
      :show-limit="20"
      :events="events"
      @event-clicked="handleCalendarEventClick"
    ></vue-calendar>
  </div>
</template>

<script>
export default {
  name: "Calendar",
  props: {
    events: Array
  },
  methods: {
    handleCalendarEventClick(event) {
      this.$emit("clicked", event);
    }
  }
};
</script>

<style lang="scss">
.calendar {
  margin-top: 24px;
  .vue-calendar {
    display: grid;
    grid-template-rows: 10% 90%;
    background: #fff;
    margin: 0 auto;
  }
  .calendar-header {
    align-items: center;
  }
  .header-left,
  .header-right {
    flex: 1;
  }
  .header-center {
    flex: 3;
    text-align: center;
  }
  .title {
    margin: 0 5px;
    font-weight: bold;
  }
  .next-month,
  .prev-month {
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 100%;
    width: 24px;
    height: 24px;
    line-height: 21px;
    text-align: center;
    display: inline-block;
  }
  .calendar-body {
    display: grid;
    grid-template-rows: 5% 95%;
  }
  .days-header {
    display: grid;
    grid-auto-columns: 14.25%;
    grid-template-areas: "a a a a a a a";
    border-top: 1px solid #e0e0e0;
    border-left: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
  }
  .days-body {
    display: grid;
    grid-template-rows: auto;
  }
  .day-number {
    text-align: right;
    margin-right: 10px;
  }
  .day-label {
    text-align: center;
    border-right: 1px solid #e0e0e0;
  }
  .week-row {
    display: grid;
    grid-template-areas: "a a a a a a a";
    grid-row-gap: 5px;
    grid-auto-columns: 14.25%;
    border-left: 1px solid #e0e0e0;
  }
  .week-day {
    padding: 4px;
    border-right: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
  }
  .week-day.disabled {
    background-color: #f5f5f5;
  }
  .week-day.not-current > .day-number {
    color: #c3c3c3;
  }
  .week-day.today > .day-number {
    font-weight: 700;
    color: red;
  }
  .events {
    font-size: 12px;
    cursor: pointer;
    padding: 0 0 0 4px;
  }
  .events .event {
    height: 18px;
    line-height: 18px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0 4px 2px 0;
    color: rgba(0, 0, 0, 0.87);
    background-color: #eee;
    border-radius: 4px;
    cursor: pointer;
    padding: 0 4px;
    font-weight: bold;
  }
  .events .more-link {
    color: rgba(0, 0, 0, 0.38);
  }

  .event.gumroad {
    background: #ff90e9;
    color: #fff;
  }
  .event.invoice {
    background: #0186f9;
    color: #fff;
  }
}
</style>
