<template>
  <!-- Bannerify -->
  <svg
    v-if="pluginName == 'Bannerify'"
    height="511pt"
    viewBox="1 -19 511.99998 511"
    width="511pt"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m512 92.5c0 38.058594-30.851562 68.910156-68.914062 68.910156-38.058594 0-68.910157-30.851562-68.910157-68.910156 0-38.0625 30.851563-68.914062 68.910157-68.914062 38.0625 0 68.914062 30.851562 68.914062 68.914062zm0 0"
      fill="#de8957"
    />
    <path
      d="m493.730469 139.21875c-12.75-31.085938-31.664063-59.628906-56.136719-84.105469-8.496094-8.496093-17.496094-16.308593-26.910156-23.441406-21.722656 11.597656-36.511719 34.480469-36.511719 60.824219 0 38.0625 30.855469 68.914062 68.914063 68.914062 20.027343 0 38.050781-8.550781 50.644531-22.191406zm0 0"
      fill="#c76d33"
    />
    <path
      d="m446.183594 117.058594c15.246094 0 27.652344-12.40625 27.652344-27.65625 0-15.246094-12.40625-27.652344-27.652344-27.652344-15.25 0-27.65625 12.40625-27.65625 27.652344 0 15.25 12.40625 27.65625 27.65625 27.65625zm0 0"
      fill="#f5d5c4"
    />
    <path
      d="m137.824219 92.5c0 38.058594-30.851563 68.910156-68.910157 68.910156-38.0625 0-68.914062-30.851562-68.914062-68.910156 0-38.0625 30.851562-68.914062 68.914062-68.914062 38.058594 0 68.910157 30.851562 68.910157 68.914062zm0 0"
      fill="#de8957"
    />
    <path
      d="m18.269531 139.21875c12.59375 13.640625 30.617188 22.191406 50.644531 22.191406 38.058594 0 68.914063-30.851562 68.914063-68.914062 0-26.34375-14.789063-49.226563-36.511719-60.824219-9.414062 7.132813-18.414062 14.945313-26.910156 23.441406-24.472656 24.476563-43.390625 53.019531-56.136719 84.105469zm0 0"
      fill="#c76d33"
    />
    <path
      d="m65.816406 117.058594c-15.246094 0-27.652344-12.40625-27.652344-27.65625 0-15.246094 12.40625-27.652344 27.652344-27.652344 15.25 0 27.65625 12.40625 27.65625 27.652344 0 15.25-12.40625 27.65625-27.65625 27.65625zm0 0"
      fill="#f5d5c4"
    />
    <path
      d="m472.378906 98.246094c-8.257812-12.847656-17.71875-25.027344-28.324218-36.40625-14.257813 1.089844-25.527344 13.03125-25.527344 27.5625 0 15.25 12.40625 27.65625 27.652344 27.65625 12.15625 0 22.5-7.886719 26.199218-18.8125zm0 0"
      fill="#edb798"
    />
    <path
      d="m67.945312 61.839844c-10.605468 11.378906-20.066406 23.5625-28.324218 36.40625 3.699218 10.925781 14.042968 18.8125 26.195312 18.8125 15.25 0 27.65625-12.40625 27.65625-27.65625 0-14.53125-11.269531-26.472656-25.527344-27.5625zm0 0"
      fill="#edb798"
    />
    <path
      d="m492.207031 236.710938c0 130.453124-105.753906 236.207031-236.207031 236.207031s-236.207031-105.753907-236.207031-236.207031c0-130.457032 105.753906-236.210938 236.207031-236.210938s236.207031 105.753906 236.207031 236.210938zm0 0"
      fill="#de8957"
    />
    <path
      d="m61 236.710938c0-123.511719 94.800781-224.863282 215.605469-235.304688-6.792969-.589844-13.660157-.90625-20.605469-.90625-130.453125 0-236.207031 105.753906-236.207031 236.210938 0 130.453124 105.753906 236.207031 236.207031 236.207031 6.945312 0 13.8125-.316407 20.605469-.90625-120.804688-10.441407-215.605469-111.792969-215.605469-235.300781zm0 0"
      fill="#c76d33"
    />
    <path
      d="m287.109375 216.8125c22.25 11.347656 37.484375 34.472656 37.484375 61.15625 0 37.886719-30.707031 68.59375-68.59375 68.59375s-68.59375-30.707031-68.59375-68.59375c0-26.683594 15.234375-49.808594 37.484375-61.15625zm0 0"
      fill="#f5d5c4"
    />
    <path
      d="m287.109375 216.8125c-3.351563 14.070312-16.007813 24.539062-31.109375 24.539062s-27.757812-10.46875-31.109375-24.539062c9.335937-4.753906 19.90625-7.4375 31.109375-7.4375s21.773438 2.683594 31.109375 7.4375zm0 0"
      fill="#423e4f"
    />
    <path
      d="m284.1875 258.460938c-3.210938-2.808594-8.089844-2.480469-10.902344.730468-1.371094 1.570313-3.460937 2.472656-5.734375 2.472656-2.269531 0-4.359375-.902343-5.730469-2.472656-1.445312-1.652344-3.625-2.632812-5.816406-2.632812-2.203125 0-4.367187.972656-5.816406 2.632812-1.375 1.570313-3.464844 2.472656-5.738281 2.472656-2.269531 0-4.363281-.902343-5.734375-2.472656-2.808594-3.210937-7.6875-3.539062-10.902344-.730468-3.210938 2.808593-3.539062 7.6875-.730469 10.902343 4.304688 4.925781 10.636719 7.753907 17.367188 7.753907 4.128906 0 8.101562-1.066407 11.554687-3.023438 3.449219 1.953125 7.421875 3.023438 11.550782 3.023438 6.730468 0 13.0625-2.828126 17.367187-7.753907 2.808594-3.214843 2.480469-8.09375-.734375-10.902343zm0 0"
      fill="#423e4f"
    />
    <path
      d="m140.597656 243.339844h-20.308594c-4.265624 0-7.726562 3.460937-7.726562 7.726562 0 4.269532 3.460938 7.726563 7.726562 7.726563h20.308594c4.265625 0 7.726563-3.457031 7.726563-7.726563 0-4.265625-3.460938-7.726562-7.726563-7.726562zm0 0"
      fill="#ff9db0"
    />
    <path
      d="m143.335938 200.90625c-4.265626 0-7.726563 3.460938-7.726563 7.726562v17.179688c0 4.269531 3.460937 7.726562 7.726563 7.726562 4.265624 0 7.726562-3.457031 7.726562-7.726562v-17.179688c0-4.265624-3.460938-7.726562-7.726562-7.726562zm0 0"
      fill="#423e4f"
    />
    <path
      d="m391.710938 243.339844h-20.308594c-4.265625 0-7.726563 3.460937-7.726563 7.726562 0 4.269532 3.460938 7.726563 7.726563 7.726563h20.308594c4.269531 0 7.726562-3.457031 7.726562-7.726563 0-4.265625-3.460938-7.726562-7.726562-7.726562zm0 0"
      fill="#ff9db0"
    />
    <path
      d="m376.390625 225.8125v-17.179688c0-4.265624-3.457031-7.726562-7.726563-7.726562-4.269531 0-7.726562 3.460938-7.726562 7.726562v17.179688c0 4.269531 3.457031 7.726562 7.726562 7.726562 4.269532 0 7.726563-3.457031 7.726563-7.726562zm0 0"
      fill="#423e4f"
    />
  </svg>
  <!-- Commentful -->

  <svg
    v-else-if="pluginName == 'Commentful'"
    id="Capa_1"
    enable-background="new 0 0 497 497"
    height="512"
    viewBox="0 0 497 497"
    width="512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m465.885 422.631c-1.353 25.893-27.021 43.488-51.593 35.212-41.392-13.941-81.106-32.87-117.971-56.294-5.635-3.58-11.488-7.589-14.208-13.686-4.18-9.37.381-20.207 5.578-29.053 18.791-31.983 47.028-58.338 80.229-74.882 17.806-8.872 37.007-14.904 56.664-17.934 11.67-1.799 37.563-7.79 46.179 3.953 4.792 6.531 2.194 17.328 1.809 24.685-.398 7.649-4.039 77.334-6.687 127.999z"
      fill="#fcd2a5"
    />
    <path
      d="m497 235.068c0-109.338-111.257-197.975-248.5-197.975s-248.5 88.637-248.5 197.975 111.257 197.975 248.5 197.975c43.27 0 89.28-8.381 128.168-28.411 6.103-3.143 13.567-1.266 17.648 4.253 12.891 17.436 26.818 34.106 41.696 49.88 16.06-3.965 28.927-18.01 29.874-36.135 1.629-31.172 3.634-69.542 5.029-96.236.111-2.132.75-4.19 1.816-6.039 14.673-25.453 24.269-55.857 24.269-85.287z"
      fill="#fff1ce"
    />
    <path
      d="m94.467 79.703c-57.542 36.259-94.467 92.378-94.467 155.365 0 85.982 68.808 159.18 164.943 186.521-139.573-110.776-104.534-295.592-70.476-341.886z"
      fill="#fcd2a5"
    />
    <path
      d="m105.341 188.214c.007 13.438-13.667 24.338-30.541 24.346s-30.559-10.878-30.566-24.315c-.007-13.438 13.667-24.338 30.541-24.347s30.559 10.878 30.566 24.316z"
      fill="#ffa1ac"
    />
    <path
      d="m258.88 188.161c.007 13.438 13.691 24.324 30.566 24.315 16.874-.008 30.548-10.908 30.541-24.346s-13.691-24.325-30.566-24.316c-16.874.009-30.548 10.909-30.541 24.347z"
      fill="#ffa1ac"
    />
    <g fill="#333942">
      <path
        d="m212.817 179.236c-.009-18.509-13.419-20.242-29.96-20.233-16.54.008-29.949 1.754-29.939 20.263.009 18.509 13.426 33.506 29.966 33.498 16.541-.008 29.942-15.019 29.933-33.528z"
      />
      <path
        d="m90.319 171.314c-4.141 0-7.498-3.355-7.5-7.496-.003-5.888 2.287-11.424 6.448-15.59 4.162-4.166 9.696-6.461 15.584-6.464h.011c12.15 0 22.036 9.88 22.043 22.032.002 4.142-3.354 7.501-7.496 7.504-.001 0-.003 0-.004 0-4.141 0-7.498-3.355-7.5-7.496-.002-3.882-3.161-7.04-7.043-7.04-.001 0-.002 0-.004 0-1.881.001-3.649.734-4.979 2.065s-2.061 3.1-2.06 4.981c.002 4.142-3.354 7.501-7.496 7.504-.001 0-.003 0-.004 0z"
      />
      <path
        d="m246.315 171.249c-4.141 0-7.498-3.355-7.5-7.497-.006-12.154 9.877-22.047 22.032-22.054h.011c5.884 0 11.416 2.29 15.579 6.449 4.165 4.161 6.461 9.695 6.464 15.583.002 4.142-3.354 7.501-7.496 7.503-.001 0-.003 0-.004 0-4.141 0-7.498-3.355-7.5-7.497-.001-1.881-.734-3.649-2.065-4.979-1.33-1.329-3.098-2.061-4.978-2.061-.001 0-.002 0-.003 0-3.884.002-7.041 3.163-7.039 7.047.002 4.142-3.354 7.501-7.497 7.503-.002.003-.003.003-.004.003z"
      />
    </g>
  </svg>
  <!-- Convertify -->
  <svg
    v-else-if="pluginName == 'Convertify'"
    id="Capa_1"
    enable-background="new 0 0 497 497"
    height="512"
    viewBox="0 0 497 497"
    width="512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m43.672 21.814c3.727-9.415 11.899-16.362 21.794-18.515 23.514-5.115 69.247-8.905 105.105 25.754l-117.618 120.965s-39.188-52.647-9.281-128.204z"
      fill="#ff82b3"
    />
    <path
      d="m209.221 496.995h-80.944c0-48.842 40.038-82.002 40.038-82.002h160.37s40.038 33.16 40.038 82.002h-80.944l-39.279-19.64z"
      fill="#ff82b3"
    />
    <path
      d="m188.315 414.993h-20s-40.038 33.16-40.038 82.002h20c0-48.842 40.038-82.002 40.038-82.002z"
      fill="#ff5d9c"
    />
    <path
      d="m453.328 21.814c-3.727-9.415-11.899-16.362-21.794-18.515-23.514-5.115-69.247-8.905-105.105 25.754l117.617 120.965c.001 0 39.189-52.647 9.282-128.204z"
      fill="#ff82b3"
    />
    <path
      d="m460.313 112.667c-11.139-21.424-25.61-41.254-43.175-58.818-18.301-18.301-39.052-33.261-61.513-44.574-10.021 4.461-19.938 10.831-29.195 19.778l117.617 120.965s10.251-13.781 16.266-37.351z"
      fill="#ff5d9c"
    />
    <path
      d="m36.687 112.667c6.015 23.57 16.266 37.351 16.266 37.351l117.617-120.965c-9.257-8.948-19.174-15.317-29.195-19.778-22.462 11.313-43.213 26.273-61.513 44.574-17.564 17.564-32.035 37.393-43.175 58.818z"
      fill="#ff5d9c"
    />
    <path
      d="m414.024 131.154s-.099.126-.277.38c1.164-1.66 26.215-38.423 5.962-92.59-5.268-1.026-11.833-1.911-19.062-2.003-19.202-.247-37.964 5.726-51.934 19.229z"
      fill="#e6e6e6"
    />
    <path
      d="m82.976 131.154s.099.126.277.38c-1.164-1.66-26.215-38.423-5.962-92.59 5.268-1.026 11.833-1.911 19.062-2.003 19.202-.247 37.964 5.726 51.934 19.229z"
      fill="#e6e6e6"
    />
    <g fill="#c5c3c9">
      <path
        d="m414.024 131.154-.01-.012c-.125.188-.218.323-.267.392.178-.254.277-.38.277-.38z"
      />
      <path
        d="m426.218 63.452c-2.932-3.268-5.952-6.475-9.08-9.603-5.996-5.997-12.263-11.623-18.76-16.891-18.404.237-36.246 6.242-49.664 19.212l65.3 74.972c2.418-3.628 18.25-29.2 12.204-67.69z"
      />
      <path
        d="m83.253 131.534c-.048-.069-.141-.204-.266-.392l-.01.012c-.001 0 .098.126.276.38z"
      />
      <path
        d="m70.782 63.452c-6.046 38.49 9.786 64.062 12.204 67.69l65.3-74.972c-13.419-12.97-31.26-18.975-49.665-19.212-6.497 5.269-12.764 10.895-18.76 16.891-3.127 3.128-6.148 6.335-9.079 9.603z"
      />
    </g>
    <circle cx="248.5" cy="222.487" fill="#ffaacb" r="208.49" />
    <path
      d="m398.878 164.516c8.495 15.941 13.196 35.137 13.196 57.975 0 90.187-73.377 163.564-163.574 163.564s-163.574-73.377-163.574-163.564c0-22.839 4.702-42.034 13.196-57.975l58.131-24.54h185.257z"
      fill="#ffdcce"
    />
    <path
      d="m114.925 222.491c0-22.839 4.702-42.034 13.196-57.975 3.441-6.462 7.519-12.376 12.144-17.792 0 0-42.144 17.792-42.144 17.792-2.467 4.629-4.603 9.542-6.408 14.731-4.41 12.676-6.788 27.038-6.788 43.244 0 45.094 18.344 85.984 47.963 115.603 29.619 29.618 70.513 47.962 115.611 47.962.026 0 .052-.001.078-.001 3.756 0 7.495-.142 11.241-.397 1.225-.085 2.446-.184 3.68-.296-83.19-7.601-148.573-77.741-148.573-162.871z"
      fill="#ffcabd"
    />
    <path
      d="m398.878 164.516-14.474 6.628c-17.059 7.806-36.664 7.806-53.713 0l-.819-.379c-17.049-7.806-36.654-7.806-53.703 0l-.818.379c-17.049 7.806-36.654 7.806-53.703 0l-.818-.379c-17.049-7.806-36.654-7.806-53.703 0-.279.13-.549.25-.819.379-17.049 7.806-36.654 7.806-53.713 0l-14.474-6.628c25.035-47.015 83.02-65.671 150.378-65.671s125.345 18.656 150.379 65.671z"
      fill="#d97c40"
    />
    <path
      d="m98.122 164.516 14.474 6.628c3.482 1.593 7.073 2.843 10.724 3.786 1.434-3.612 3.03-7.088 4.803-10.414 23.155-43.484 74.504-62.695 135.378-65.338-2.368-.103-4.751-.181-7.145-.237-11.048-.278-22.12.001-33.139.84-56.339 4.293-103.256 23.721-125.095 64.735z"
      fill="#af602d"
    />
    <path
      d="m333.421 222.007v17.97c0 4.142 3.358 7.5 7.5 7.5s7.5-3.358 7.5-7.5v-17.97c0-4.142-3.358-7.5-7.5-7.5s-7.5 3.358-7.5 7.5z"
      fill="#423e4f"
    />
    <path
      d="m353.158 258.89h-24.475c-4.142 0-7.5 3.358-7.5 7.5s3.358 7.5 7.5 7.5h24.475c4.142 0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5z"
      fill="#ff889e"
    />
    <path
      d="m156.08 247.477c4.142 0 7.5-3.358 7.5-7.5v-17.97c0-4.142-3.358-7.5-7.5-7.5s-7.5 3.358-7.5 7.5v17.97c0 4.142 3.358 7.5 7.5 7.5z"
      fill="#423e4f"
    />
    <path
      d="m168.318 258.89h-24.476c-4.142 0-7.5 3.358-7.5 7.5s3.358 7.5 7.5 7.5h24.476c4.142 0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5z"
      fill="#ff889e"
    />
    <path
      d="m269.966 252.415c-3.119-2.726-7.857-2.408-10.583.71-2.639 3.019-6.606 4.75-10.884 4.75s-8.245-1.731-10.883-4.75c-2.727-3.119-7.464-3.437-10.583-.711s-3.437 7.464-.711 10.583c5.487 6.277 13.57 9.878 22.177 9.878 8.606 0 16.689-3.601 22.177-9.878 2.727-3.118 2.409-7.856-.71-10.582z"
      fill="#423e4f"
    />
    <path
      d="m164.013 85.769c4.142 0 7.5-3.358 7.5-7.5v-14.976c0-4.142-3.358-7.5-7.5-7.5s-7.5 3.358-7.5 7.5v14.976c0 4.142 3.358 7.5 7.5 7.5z"
      fill="#423e4f"
    />
    <path
      d="m332.987 85.769c4.142 0 7.5-3.358 7.5-7.5v-14.976c0-4.142-3.358-7.5-7.5-7.5s-7.5 3.358-7.5 7.5v14.976c0 4.142 3.358 7.5 7.5 7.5z"
      fill="#423e4f"
    />
    <path
      d="m284.808 56.892c-3.119-2.726-7.856-2.408-10.583.711-2.431 2.781-6.09 4.376-10.039 4.376s-7.608-1.595-10.039-4.375c-1.485-1.699-3.758-2.66-6.01-2.55-2.01.097-3.958 1.034-5.283 2.55-2.431 2.781-6.091 4.376-10.04 4.376s-7.608-1.595-10.04-4.376c-2.726-3.119-7.464-3.437-10.583-.711s-3.437 7.464-.71 10.583c5.28 6.04 13.055 9.504 21.333 9.504 5.68 0 11.123-1.632 15.686-4.607 4.563 2.975 10.006 4.607 15.686 4.607 8.278 0 16.054-3.464 21.333-9.504 2.726-3.121 2.408-7.859-.711-10.584z"
      fill="#423e4f"
    />
    <path
      d="m318.188 497v-30.033c0-4.142 3.358-7.5 7.5-7.5s7.5 3.358 7.5 7.5v30.033z"
      fill="#ff5d9c"
    />
    <path
      d="m163.812 497v-30.033c0-4.142 3.358-7.5 7.5-7.5s7.5 3.358 7.5 7.5v30.033z"
      fill="#ff5d9c"
    />
    <path
      d="m287.779 496.995h-78.558c0-21.691 17.588-39.279 39.279-39.279s39.279 17.588 39.279 39.279z"
      fill="#e6e6e6"
    />
  </svg>

  <!-- CopyDoc -->
  <svg
    v-else-if="pluginName == 'CopyDoc'"
    id="Capa_1"
    enable-background="new 0 0 497 497"
    height="512"
    viewBox="0 0 497 497"
    width="512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="m420.962 0h-229.03c-20.518 0-37.151 16.633-37.151 37.151v306.805c0 20.518 16.633 37.151 37.151 37.151h229.03c20.518 0 37.151-16.633 37.151-37.151v-306.805c0-20.518-16.633-37.151-37.151-37.151z"
        fill="#f9f097"
      />
      <path
        d="m363.015 27.947h-207.083c-.75 2.943-1.151 6.027-1.151 9.204v306.804c0 20.518 16.633 37.151 37.151 37.151h229.03c3.177 0 6.26-.401 9.204-1.151v-284.857c0-37.027-30.124-67.151-67.151-67.151z"
        fill="#fbc072"
      />
      <path
        d="m363.015 57.947h-229.03c-20.518 0-37.151 16.633-37.151 37.151v306.805c0 20.518 16.633 37.151 37.151 37.151h229.03c20.518 0 37.151-16.633 37.151-37.151v-306.805c0-20.518-16.633-37.151-37.151-37.151z"
        fill="#a4e6ff"
      />
      <path
        d="m305.068 85.894h-207.083c-.75 2.943-1.151 6.027-1.151 9.204v306.804c0 20.518 16.633 37.151 37.151 37.151h229.03c3.177 0 6.26-.4 9.203-1.151v-284.857c.001-37.027-30.123-67.151-67.15-67.151z"
        fill="#5bd6fd"
      />
      <path
        d="m305.068 115.894h-178.292l-28.782 62.994-59.107 24.894v256.067c0 20.518 16.633 37.151 37.151 37.151h229.03c20.518 0 37.151-16.633 37.151-37.151v-306.804c0-20.518-16.633-37.151-37.151-37.151z"
        fill="#ff97c0"
      />
      <path
        d="m126.776 115.894-28.782 62.994-59.107 24.894v30h63.481c30 0 54.407-24.407 54.407-54.407v-63.481z"
        fill="#ff71a7"
      />
      <path
        d="m38.887 203.782h63.481c13.48 0 24.407-10.928 24.407-24.407v-63.481z"
        fill="#ff97c0"
      />
      <g fill="#ffbdd7">
        <ellipse cx="102.034" cy="324.568" rx="32.662" ry="26.01" />
        <ellipse cx="279.072" cy="324.568" rx="32.662" ry="26.01" />
      </g>
      <g>
        <path
          d="m121.069 307.03c-4.142 0-7.5-3.358-7.5-7.5v-13.352c0-4.142 3.358-7.5 7.5-7.5s7.5 3.358 7.5 7.5v13.352c0 4.142-3.358 7.5-7.5 7.5z"
          fill="#423e4f"
        />
      </g>
      <g>
        <path
          d="m260.037 307.03c-4.142 0-7.5-3.358-7.5-7.5v-13.352c0-4.142 3.358-7.5 7.5-7.5s7.5 3.358 7.5 7.5v13.352c0 4.142-3.358 7.5-7.5 7.5z"
          fill="#423e4f"
        />
      </g>
      <g>
        <path
          d="m190.553 314.978c-9.188 0-17.927-3.979-23.973-10.918-2.721-3.123-2.396-7.86.727-10.582 3.123-2.721 7.861-2.396 10.582.727 3.197 3.669 7.813 5.773 12.665 5.773s9.467-2.104 12.665-5.773c2.722-3.124 7.459-3.448 10.582-.727s3.448 7.459.727 10.582c-6.048 6.939-14.786 10.918-23.975 10.918z"
          fill="#423e4f"
        />
      </g>
    </g>
  </svg>

  <!-- Crypto -->
  <svg
    v-else-if="pluginName == 'Crypto'"
    width="512"
    height="512"
    viewBox="0 0 512 512"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M361.942 309C346.444 309 333.88 296.447 333.88 280.967V136.884C333.88 93.9763 298.945 59.067 256 59.067C213.055 59.067 178.12 93.9763 178.12 136.884V280.967C178.12 296.447 165.556 309 150.058 309C134.564 309 122 296.447 122 280.967V136.884C122 63.0588 182.111 3 256 3C329.889 3 390 63.0588 390 136.884V280.967C390 296.447 377.436 309 361.942 309Z"
      fill="#6E7CA8"
    />
    <path
      d="M334 174V280.889C334 296.412 346.537 309 362.002 309C377.463 309 390 296.412 390 280.889V183.021C371.435 179.209 352.756 176.203 334 174Z"
      fill="#54669E"
    />
    <path
      d="M122 183.021V280.889C122 296.412 134.537 309 149.998 309C165.463 309 178 296.412 178 280.889V174C159.244 176.203 140.565 179.209 122 183.021Z"
      fill="#54669E"
    />
    <path
      d="M406.56 492.863C307.492 515.712 204.513 515.712 105.445 492.863C84.6962 488.075 70 469.612 70 448.33V257.67C70 236.388 84.6962 217.925 105.445 213.137C204.513 190.288 307.487 190.288 406.56 213.137C427.304 217.925 442 236.388 442 257.67V448.33C442.005 469.612 427.309 488.075 406.56 492.863Z"
      fill="#89D47F"
    />
    <path
      d="M229.7 197C187.972 198.627 146.358 204.152 105.413 213.589C84.6832 218.37 70 236.805 70 258.056V448.432C70 469.682 84.6832 488.118 105.413 492.894C167.714 507.255 231.558 512.57 295 508.853C227.436 428.627 213.827 274.215 229.7 197V197Z"
      fill="#5EC783"
    />
    <path
      d="M200.29 461.997H157.125C151.799 461.997 147.037 458.813 145.001 453.894C142.964 448.975 144.078 443.362 147.845 439.595L186.33 401.109C189.376 398.064 191.078 394.015 191.111 389.707C191.183 381.092 184.26 373.716 175.688 373.266C171.142 373.032 166.834 374.61 163.555 377.722C160.27 380.834 158.458 385.041 158.458 389.569C158.458 394.636 154.352 398.748 149.279 398.748C144.212 398.748 140.1 394.636 140.1 389.569C140.1 380.093 144.044 370.924 150.919 364.403C157.89 357.786 167.016 354.43 176.654 354.932C194.897 355.898 209.617 371.564 209.469 389.856C209.393 399.011 205.783 407.616 199.31 414.094L169.765 443.639H200.29C205.358 443.639 209.469 447.746 209.469 452.818C209.469 457.886 205.358 461.997 200.29 461.997V461.997Z"
      fill="white"
    />
    <path
      d="M255.403 461.997C255.312 461.997 255.221 461.997 255.13 461.997C245.975 461.921 237.365 458.311 230.892 451.833C227.306 448.253 227.306 442.439 230.892 438.854C234.477 435.273 240.286 435.273 243.872 438.858C246.922 441.904 250.971 443.601 255.279 443.639H255.408C263.965 443.639 271.27 436.745 271.72 428.212C271.959 423.665 270.376 419.362 267.264 416.078C264.147 412.794 259.94 410.987 255.417 410.987H242.543C232.422 410.987 224.194 402.754 224.194 392.638V373.233C224.194 363.112 232.422 354.884 242.543 354.884H280.923C285.99 354.884 290.102 358.991 290.102 364.063C290.102 369.131 285.99 373.242 280.923 373.242H242.543L242.552 392.638L255.417 392.628C264.893 392.628 274.062 396.573 280.583 403.447C287.195 410.418 290.561 419.558 290.049 429.182C289.093 447.33 273.579 461.997 255.403 461.997V461.997Z"
      fill="white"
    />
    <path
      d="M337.216 392.628C333.238 392.628 329.419 393.307 325.862 394.545C327.521 391.586 329.576 388.823 332.01 386.332C339.305 378.86 349.072 374.677 359.514 374.557C364.586 374.5 368.645 370.34 368.583 365.273C368.525 360.239 364.428 356.199 359.408 356.199C359.375 356.199 359.337 356.199 359.298 356.199C343.957 356.381 329.6 362.524 318.877 373.501C308.154 384.482 302.35 398.977 302.532 414.214V427.313C302.532 446.436 318.088 461.997 337.216 461.997C356.339 461.997 371.901 446.436 371.901 427.313C371.896 408.185 356.339 392.628 337.216 392.628ZM337.216 443.639C328.214 443.639 320.89 436.315 320.89 427.313C320.89 418.311 328.214 410.987 337.216 410.987C346.218 410.987 353.538 418.311 353.538 427.313C353.538 436.315 346.218 443.639 337.216 443.639V443.639Z"
      fill="white"
    />
    <path
      d="M199.258 302.855C199.258 288.259 184.399 276.432 166.074 276.432C147.75 276.432 132.896 288.259 132.896 302.855C132.896 317.446 147.75 329.278 166.074 329.278C184.399 329.278 199.258 317.446 199.258 302.855V302.855Z"
      fill="white"
    />
    <path
      d="M379.105 302.855C379.105 288.259 364.251 276.432 345.927 276.432C327.602 276.432 312.743 288.259 312.743 302.855C312.743 317.446 327.602 329.278 345.927 329.278C364.251 329.278 379.105 317.446 379.105 302.855V302.855Z"
      fill="white"
    />
    <path
      d="M185.5 286C180.255 286 176 281.904 176 276.844V265.156C176 260.101 180.255 256 185.5 256C190.75 256 195 260.101 195 265.156V276.844C195 281.904 190.75 286 185.5 286Z"
      fill="#4E5660"
    />
    <path
      d="M326.5 286C321.25 286 317 281.904 317 276.844V265.156C317 260.101 321.25 256 326.5 256C331.745 256 336 260.101 336 265.156V276.844C336 281.904 331.745 286 326.5 286Z"
      fill="#4E5660"
    />
    <path
      d="M256 287.664C248.004 287.664 240.402 284.203 235.141 278.164C232.418 275.043 232.746 270.305 235.867 267.586C238.992 264.863 243.727 265.187 246.449 268.312C248.859 271.078 252.344 272.664 256 272.664C259.66 272.664 263.141 271.078 265.555 268.312C268.273 265.187 273.012 264.863 276.133 267.586C279.258 270.305 279.582 275.043 276.863 278.164C271.602 284.203 263.996 287.664 256 287.664Z"
      fill="#4E5660"
    />
  </svg>

  <!-- Emailify -->
  <svg
    v-else-if="pluginName == 'Emailify'"
    id="Capa_1"
    enable-background="new 0 0 497 497"
    height="512"
    viewBox="0 0 497 497"
    width="512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <g>
        <path
          d="m.009 128.5h-.009v240c0 33.137 26.863 60 60 60h377c33.137 0 60-26.863 60-60v-239.993z"
          fill="#ffb357"
        />
        <g>
          <path
            d="m336.417 207.912c-23.315-23.315-54.938-36.414-87.911-36.414s-64.595 13.099-87.911 36.414l-160.595 160.588c0 33.137 26.863 60 60 60h377c33.137 0 60-26.863 60-60z"
            fill="#ffce71"
          />
          <path
            d="m83.5 368.5v-83.496h-83.5v83.496c0 33.137 26.863 60 60 60h83.5c-33.137 0-60-26.863-60-60z"
            fill="#ffb357"
          />
        </g>
        <g>
          <path
            d="m436.993 68.5h-376.993c-33.137 0-60 26.863-60 60l160.592 160.593c23.315 23.315 54.938 36.414 87.911 36.414s64.595-13.099 87.911-36.414l160.586-160.593c0-33.141-26.866-60-60.007-60z"
            fill="#fce3a0"
          />
          <path
            d="m83.5 211.996v-83.496c0-33.137 26.863-60 60-60h-83.5c-33.137 0-60 26.863-60 60l83.496 83.496z"
            fill="#ffce71"
          />
        </g>
      </g>
      <g>
        <g>
          <g>
            <ellipse
              cx="174.589"
              cy="238.119"
              fill="#ff8e9e"
              rx="25"
              ry="19.909"
            />
          </g>
          <g>
            <ellipse
              cx="322.411"
              cy="238.119"
              fill="#ff8e9e"
              rx="25"
              ry="19.909"
            />
          </g>
        </g>
      </g>
      <g fill="#313d40">
        <path
          d="m186.051 206.115c-4.143 0-7.5 3.357-7.5 7.5v12c0 4.143 3.357 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-12c0-4.142-3.358-7.5-7.5-7.5z"
        />
        <path
          d="m310.948 206.115c-4.143 0-7.5 3.357-7.5 7.5v12c0 4.143 3.357 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-12c0-4.142-3.357-7.5-7.5-7.5z"
        />
        <path
          d="m269.891 219.604c-3.122-2.722-7.861-2.397-10.581.728-2.729 3.132-6.669 4.928-10.81 4.928s-8.08-1.796-10.81-4.928c-2.721-3.124-7.459-3.448-10.581-.728-3.123 2.721-3.449 7.459-.728 10.581 5.578 6.402 13.641 10.074 22.118 10.074s16.54-3.672 22.118-10.074c2.723-3.122 2.397-7.861-.726-10.581z"
        />
      </g>
    </g>
  </svg>

  <!-- Favvy -->
  <svg
    v-else-if="pluginName == 'Favvy'"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <path
      fill="#f3ce72"
      d="M390.93 327.77H121.07l33.38 164.31A24.87 24.87 0 00178.82 512h154.36a24.87 24.87 0 0024.37-19.92l33.38-164.3z"
    />
    <path
      d="M390.93 327.77H121.07l33.38 164.31A24.87 24.87 0 00178.82 512h75.53a24.87 24.87 0 01-24.37-19.92L201.8 353.4h183.92l5.2-25.63z"
      opacity=".08"
    />
    <path
      d="M385.66 353.7l-162.75 60.1-92.57-40.4 3.65 17.96 224.14 97.83 3.06-15.03-118.43-51.7L382.15 371z"
      opacity=".1"
    />
    <path
      fill="#f0bdd5"
      d="M256 281.3c118.84 0 165.99-8.97 165.99-88.96 0-61.58-45.81-116.44-106.14-142.05-23.67-10.04-38.13-16.3-52.13-45.42a8.56 8.56 0 00-15.44 0c-14 29.11-28.46 35.38-52.13 45.42C135.82 75.9 90 130.76 90 192.34c0 80 47.15 88.97 165.99 88.97z"
    />
    <path
      fill="#e978a0"
      d="M247.21 244.23C131.4 240.1 163.35 120.41 200.6 92.15c43.49-33 44.07-41.15 47.68-87.29-14 29.12-28.46 35.39-52.13 45.43C135.82 75.9 90 130.76 90 192.34c0 80 47.15 88.97 165.99 88.97 22.22 0 41.94-.32 59.3-1.35-9.34-19.08-31.97-34.44-68.09-35.73z"
      opacity=".3"
    />
    <path
      fill="#f3ce72"
      d="M396.4 327.77H115.6a43.98 43.98 0 010-87.97h280.8c24.29 0 43.98 19.7 43.98 44 0 24.28-19.7 43.97-43.99 43.97z"
    />
    <path
      fill="#fff"
      d="M396.4 327.77H115.6a43.98 43.98 0 010-87.97h280.8c24.29 0 43.98 19.7 43.98 44 0 24.28-19.7 43.97-43.99 43.97z"
      opacity=".6"
    />
    <path
      d="M156.61 283.79c0-24.3 19.7-43.98 43.99-43.98h-85a43.98 43.98 0 000 87.97h85a43.98 43.98 0 01-43.99-43.99z"
      opacity=".08"
    />
    <g fill="#485358">
      <path
        d="M193.65 154.38a7.5 7.5 0 01-7.5-7.5v-10.64a7.5 7.5 0 1115 0v10.64a7.5 7.5 0 01-7.5 7.5zM318.35 154.38a7.5 7.5 0 01-7.5-7.5v-10.64a7.5 7.5 0 1115 0v10.64a7.5 7.5 0 01-7.5 7.5zM274.6 155.01c0 10.13-8.39 18.3-18.6 17.94-9.73-.34-17.3-8.63-17.3-18.37v-12.62c0-2.7 2.2-4.9 4.9-4.9h26.1c2.71 0 4.9 2.2 4.9 4.9V155z"
      />
    </g>
    <path
      fill="#e978a0"
      d="M355.95 78.72c-.8.39-1.64.7-2.53.92l-25.87 6.48a13 13 0 01-6.32-25.22l25.87-6.48a13 13 0 018.85 24.3z"
    />
    <path
      fill="#4d94ae"
      d="M180.98 90.96a13 13 0 01-2.7 0l-26.54-2.62a13 13 0 012.56-25.87l26.54 2.62a13 13 0 01.14 25.87z"
    />
    <path
      fill="#c06376"
      d="M109.42 142.44c.83.33 1.63.74 2.4 1.24l22.32 14.6a13 13 0 01-14.24 21.76l-22.31-14.6a13 13 0 0111.83-23z"
    />
    <path
      fill="#4d94ae"
      d="M350.64 190.01c.7-.54 1.46-1.02 2.3-1.42l23.98-11.65a13 13 0 0111.36 23.38l-23.99 11.66A13 13 0 01350.64 190z"
    />
  </svg>

  <!-- HyperCrop -->
  <svg
    v-else-if="pluginName == 'HyperCrop'"
    height="512pt"
    viewBox="-20 0 512 512"
    width="512pt"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m95.414062 512c-22.917968 0-42.0625-16.140625-46.691406-37.671875-1.113281-5.179687 2.769532-10.085937 8.070313-10.085937h17.460937c11.6875 0 21.160156-9.472657 21.160156-21.15625v-278.371094c0-90.96875 73.742188-164.714844 164.710938-164.714844 90.972656 0 164.714844 73.746094 164.714844 164.714844v278.371094c0 11.683593 9.472656 21.15625 21.15625 21.15625h17.464844c5.296874 0 9.183593 4.90625 8.070312 10.085937-4.628906 21.53125-23.773438 37.671875-46.691406 37.671875zm0 0"
      fill="#3d4851"
    />
    <path
      d="m259.425781 44.226562c-66.261719.382813-119.378906 55.136719-119.378906 121.402344v58.625c0 17.304688 14.027344 31.335938 31.335937 31.335938h18.144532c8.535156 0 15.457031 6.917968 15.457031 15.453125 0 12.109375 9.816406 21.925781 21.925781 21.925781h9.34375l7.726563-2.210938 7.726562 2.210938h15.441407l7.722656-2.46875 7.726562 2.46875h10.746094c12.109375 0 21.929688-9.816406 21.929688-21.925781 0-8.535157 6.917968-15.453125 15.453124-15.453125h18.144532c17.308594 0 31.335937-14.03125 31.335937-31.335938v-59.945312c0-66.449219-54.25-120.464844-120.78125-120.082032zm0 0"
      fill="#ebebeb"
    />
    <path
      d="m259.425781 44.226562c-66.261719.382813-119.378906 55.136719-119.378906 121.402344v30.902344c0-66.261719 53.117187-121.015625 119.378906-121.398438 66.53125-.382812 120.78125 53.632813 120.78125 120.078126v-30.90625c0-66.445313-54.25-120.460938-120.78125-120.078126zm0 0"
      fill="#d5d4da"
    />
    <g fill="#333a41">
      <path
        d="m191.066406 226.71875c-4.265625 0-7.726562-3.460938-7.726562-7.726562v-11.222657c0-4.265625 3.460937-7.726562 7.726562-7.726562 4.269532 0 7.726563 3.460937 7.726563 7.726562v11.222657c0 4.265624-3.457031 7.726562-7.726563 7.726562zm0 0"
      />
      <path
        d="m329.1875 226.71875c-4.269531 0-7.726562-3.460938-7.726562-7.726562v-11.222657c0-4.265625 3.457031-7.726562 7.726562-7.726562 4.265625 0 7.726562 3.460937 7.726562 7.726562v11.222657c0 4.265624-3.460937 7.726562-7.726562 7.726562zm0 0"
      />
      <path
        d="m270.15625 228.679688-5.347656-10.332032c-1.980469-3.824218-7.472656-3.742187-9.34375.132813l-4.980469 10.332031c-1.671875 3.46875.855469 7.492188 4.703125 7.492188h10.332031c3.917969 0 6.4375-4.148438 4.636719-7.625zm0 0"
      />
      <path
        d="m236.253906 292.96875v-23.535156c0-4.265625 3.457032-7.726563 7.726563-7.726563 4.265625 0 7.726562 3.460938 7.726562 7.726563v23.535156zm0 0"
      />
      <path
        d="m267.144531 292.96875v-23.535156c0-4.265625 3.460938-7.726563 7.726563-7.726563 4.269531 0 7.726562 3.460938 7.726562 7.726563v23.535156zm0 0"
      />
    </g>
    <path
      d="m374.171875 436.75c-15.359375-17.628906-45.828125-42.976562-106.09375-71.953125-76.308594-36.164063-92.039063-62.386719-115.847656-102.085937-14.519531-24.203126-32.355469-53.9375-67.9375-91.042969-4.210938-4.390625-11.207031-4.484375-15.511719-.179688l-10.179688 10.179688c-4.164062 4.164062-4.242187 10.902343-.164062 15.15625 32.613281 34.023437 48.617188 60.710937 62.855469 84.441406 25.230469 42.066406 45.160156 75.292969 131.246093 116.085937 58.945313 28.34375 84.589844 51.550782 95.246094 64.140626 4.207032 4.96875 11.789063 5.175781 16.226563.414062l9.917969-10.640625c3.78125-4.0625 3.890624-10.328125.242187-14.515625zm0 0"
      fill="#e3986d"
    />
    <path
      d="m152.230469 262.710938c-7.957031-13.261719-16.914063-28.183594-29.230469-45.070313l-30.832031 18.945313c12.167969 16.421874 20.941406 31.042968 29.125 44.683593 3.285156 5.476563 6.480469 10.800781 9.742187 16.015625 8.710938-8.304687 17.992188-16.253906 27.835938-23.582031-2.179688-3.554687-4.375-7.210937-6.640625-10.992187zm0 0"
      fill="#d97c40"
    />
    <path
      d="m151.820312 218.636719c-7.804687-12.320313-17.453124-26.203125-31.089843-41.921875-7.039063-8.117188-19.429688-8.699219-27.28125-1.363282-35.539063 33.214844-121.492188 128.292969-84.304688 242.335938 0 0 48.054688-110.34375 137.667969-173.421875 8.25-5.804687 10.40625-17.105469 5.007812-25.628906zm0 0"
      fill="#a5b0bc"
    />
    <path
      d="m124.851562 181.554688c-1.335937-1.59375-2.699218-3.207032-4.121093-4.839844-7.039063-8.117188-19.429688-8.703125-27.28125-1.363282-33.085938 30.917969-109.832031 115.449219-90.289063 218.980469 19.847656-97.171875 84.476563-174.773437 121.691406-212.777343zm0 0"
      fill="#8597a5"
    />
    <path
      d="m268.078125 364.796875c-11.007813-5.21875-20.753906-10.226563-29.445313-15.101563-8.4375 8.613282-14.304687 19.746094-16.355468 32.15625 9.085937 5.066407 19.113281 10.21875 30.261718 15.5 31.066407 14.941407 52.875 28.449219 68.121094 39.730469 9.207032-7.949219 15.988282-18.640625 19.042969-30.796875-17.261719-12.480468-40.449219-26.496094-71.625-41.488281zm0 0"
      fill="#d97c40"
    />
    <path
      d="m310.605469 391.664062c0 16.078126-13.035157 29.117188-29.117188 29.117188s-29.117187-13.039062-29.117187-29.117188c0-16.082031 13.035156-29.121093 29.117187-29.121093s29.117188 13.039062 29.117188 29.121093zm0 0"
      fill="#ebebeb"
    />
    <path
      d="m252.371094 391.664062c0 11.859376 7.097656 22.058594 17.273437 26.597657 8.453125-5.730469 15.15625-13.714844 19.367188-23.292969 4.207031-9.582031 5.558593-19.921875 4.058593-30.023438-3.550781-1.542968-7.464843-2.402343-11.582031-2.402343-16.082031 0-29.117187 13.039062-29.117187 29.121093zm0 0"
      fill="#d5d4da"
    />
    <path
      d="m265.898438 362.316406c-5.835938-14.984375-22.714844-22.402344-37.699219-16.566406s-22.402344 22.714844-16.566407 37.699219c5.835938 14.988281 22.714844 22.402343 37.699219 16.566406s22.402344-22.714844 16.566407-37.699219zm0 0"
      fill="#ebebeb"
    />
  </svg>

  <!-- Pitchdeck -->
  <svg
    v-else-if="pluginName == 'Pitchdeck'"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 -52 512 512"
  >
    <path
      fill="#f5b96e"
      d="M433.24 177.25a44.3 44.3 0 100-88.59H78.76a44.3 44.3 0 100 88.6 44.3 44.3 0 000 88.6h354.48a44.3 44.3 0 100-88.6zm0 0"
    />
    <path
      fill="#e8af68"
      d="M65.37 221.55a44.3 44.3 0 0144.3-44.3h-30.9a44.3 44.3 0 000 88.6h30.9a44.3 44.3 0 01-44.3-44.3zm0 0"
    />
    <path
      fill="#e8af68"
      d="M65.37 132.96a44.3 44.3 0 0144.3-44.3h-30.9a44.3 44.3 0 100 88.6h30.9a44.3 44.3 0 01-44.3-44.3zm0 0"
    />
    <path
      fill="#add9e8"
      d="M438.16 407.95H73.84a36.88 36.88 0 01-36.07-29.2l-16.34-76.8h469.14l-16.34 76.8a36.88 36.88 0 01-36.07 29.2zm0 0"
    />
    <path
      fill="#9bc3d0"
      d="M68.67 378.74l-16.34-76.8h-30.9l16.34 76.8a36.88 36.88 0 0036.07 29.2h30.91a36.89 36.89 0 01-36.08-29.2zm0 0"
    />
    <path
      fill="#c2e3ee"
      d="M496.77 338.03H15.23A15.23 15.23 0 010 322.8v-41.73c0-8.4 6.82-15.22 15.23-15.22h481.55c8.4 0 15.22 6.82 15.22 15.22v41.73c0 8.41-6.82 15.23-15.23 15.23zm0 0"
    />
    <path
      fill="#add9e8"
      d="M30.9 322.8v-41.73c0-8.4 6.82-15.22 15.23-15.22h-30.9A15.23 15.23 0 000 281.08v41.72c0 8.41 6.82 15.23 15.23 15.23h30.9c-8.4 0-15.22-6.82-15.22-15.23zm0 0"
    />
    <path
      fill="#f9efd1"
      d="M433.24 0H78.76a44.3 44.3 0 100 88.6h88.6a44.3 44.3 0 0144.3 44.3v44.3c0 24.46 19.83 44.3 44.3 44.3s44.29 19.82 44.29 44.29a44.3 44.3 0 1088.6 0v-132.9a44.3 44.3 0 0144.3-44.3h.1A44.3 44.3 0 10433.23 0zm0 0"
    />
    <g fill="#4a5359">
      <path
        d="M256 74.21a27.48 27.48 0 01-23.77-13.76 7.5 7.5 0 0113-7.47c2.22 3.84 6.34 6.23 10.77 6.23s8.55-2.39 10.76-6.23a7.5 7.5 0 1113 7.48A27.49 27.49 0 01256 74.2zm0 0M321.6 44.05a7.5 7.5 0 01-7.5-7.5v-11.7a7.5 7.5 0 1115 0v11.7a7.5 7.5 0 01-7.5 7.5zm0 0M190.39 44.05a7.5 7.5 0 01-7.5-7.5v-11.7a7.5 7.5 0 1115 0v11.7a7.5 7.5 0 01-7.5 7.5zm0 0"
      />
    </g>
    <path
      fill="#e3637b"
      d="M327.46 66.16h-11.7a7.5 7.5 0 110-15h11.7a7.5 7.5 0 110 15zm0 0M196.24 66.16h-11.7a7.5 7.5 0 110-15h11.7a7.5 7.5 0 010 15zm0 0"
    />
  </svg>

  <!-- Pixelay -->
  <svg
    v-else-if="pluginName == 'Pixelay'"
    xmlns="http://www.w3.org/2000/svg"
    height="511pt"
    version="1.1"
    viewBox="0 -10 511.99826 511"
    width="511pt"
  >
    <g id="surface1">
      <path
        d="M 118.660156 343.121094 C 102.265625 346.394531 82.46875 336.652344 68.457031 329.304688 C 42.535156 315.707031 20.671875 295.550781 5.226562 271.011719 C -4.769531 255.125 0.00390625 234.144531 15.886719 224.148438 C 31.773438 214.152344 52.753906 218.925781 62.75 234.808594 C 84.265625 269 118.101562 276.351562 118.4375 276.414062 C 136.867188 279.960938 148.933594 297.777344 145.386719 316.207031 C 142.695312 330.179688 131.804688 340.496094 118.660156 343.121094 Z M 118.660156 343.121094 "
        style="
          stroke: none;
          fill-rule: nonzero;
          fill: rgb(93.72549%, 92.941176%, 93.333333%);
          fill-opacity: 1;
        "
      />
      <path
        d="M 393.339844 343.121094 C 409.730469 346.394531 429.527344 336.652344 443.539062 329.304688 C 469.460938 315.707031 491.328125 295.550781 506.773438 271.011719 C 516.769531 255.125 511.996094 234.144531 496.109375 224.148438 C 480.226562 214.152344 459.246094 218.925781 449.25 234.808594 C 427.730469 269 393.898438 276.351562 393.5625 276.414062 C 375.132812 279.960938 363.066406 297.777344 366.613281 316.207031 C 369.300781 330.179688 380.195312 340.496094 393.339844 343.121094 Z M 393.339844 343.121094 "
        style="
          stroke: none;
          fill-rule: nonzero;
          fill: rgb(93.72549%, 92.941176%, 93.333333%);
          fill-opacity: 1;
        "
      />
      <path
        d="M 465.558594 315.449219 L 465.558594 221.292969 C 459.046875 223.851562 453.261719 228.433594 449.25 234.808594 C 427.730469 269 393.898438 276.347656 393.5625 276.414062 C 375.132812 279.960938 363.066406 297.777344 366.613281 316.207031 C 369.300781 330.179688 380.195312 340.496094 393.335938 343.121094 C 409.730469 346.394531 429.527344 336.652344 443.539062 329.304688 C 451.265625 325.253906 458.617188 320.605469 465.558594 315.449219 Z M 465.558594 315.449219 "
        style="
          stroke: none;
          fill-rule: nonzero;
          fill: rgb(83.137255%, 83.137255%, 83.137255%);
          fill-opacity: 1;
        "
      />
      <path
        d="M 118.433594 276.414062 C 118.097656 276.347656 84.265625 269 62.75 234.808594 C 58.734375 228.433594 52.953125 223.851562 46.441406 221.292969 L 46.441406 315.449219 C 53.382812 320.605469 60.734375 325.25 68.457031 329.300781 C 82.46875 336.648438 102.265625 346.394531 118.660156 343.117188 C 131.804688 340.492188 142.699219 330.175781 145.386719 316.203125 C 148.929688 297.777344 136.863281 279.960938 118.433594 276.414062 Z M 118.433594 276.414062 "
        style="
          stroke: none;
          fill-rule: nonzero;
          fill: rgb(83.137255%, 83.137255%, 83.137255%);
          fill-opacity: 1;
        "
      />
      <path
        d="M 256 0.5 C 157.332031 0.5 77.34375 80.484375 77.34375 179.152344 L 77.34375 447.738281 C 77.34375 462.648438 85.101562 476.484375 97.828125 484.261719 C 112.195312 493.046875 129.617188 494.394531 144.339844 485.394531 C 158.050781 477.011719 175.296875 477.011719 189.003906 485.394531 C 202.714844 493.777344 219.960938 493.777344 233.667969 485.394531 C 247.375 477.011719 264.621094 477.011719 278.332031 485.394531 C 292.039062 493.777344 309.285156 493.777344 322.996094 485.394531 C 336.703125 477.011719 353.949219 477.011719 367.65625 485.394531 C 382.257812 494.320312 399.914062 492.980469 414.175781 484.261719 C 426.894531 476.484375 434.652344 462.648438 434.652344 447.738281 L 434.652344 179.152344 C 434.652344 80.484375 354.667969 0.5 256 0.5 Z M 256 0.5 "
        style="
          stroke: none;
          fill-rule: nonzero;
          fill: rgb(93.72549%, 92.941176%, 93.333333%);
          fill-opacity: 1;
        "
      />
      <path
        d="M 128.734375 484.265625 C 116.007812 476.484375 108.25 462.648438 108.25 447.738281 L 108.25 179.152344 C 108.25 85.691406 180.019531 9.007812 271.453125 1.171875 C 266.359375 0.734375 261.207031 0.5 256 0.5 C 157.332031 0.5 77.34375 80.484375 77.34375 179.152344 L 77.34375 447.734375 C 77.34375 462.648438 85.101562 476.484375 97.828125 484.261719 C 110.117188 491.777344 124.636719 493.84375 137.792969 488.65625 C 134.679688 487.511719 131.644531 486.042969 128.734375 484.265625 Z M 128.734375 484.265625 "
        style="
          stroke: none;
          fill-rule: nonzero;
          fill: rgb(83.137255%, 83.137255%, 83.137255%);
          fill-opacity: 1;
        "
      />
      <path
        d="M 334.058594 226.691406 C 329.792969 226.691406 326.332031 223.234375 326.332031 218.964844 L 326.332031 201.863281 C 326.332031 197.597656 329.792969 194.136719 334.058594 194.136719 C 338.324219 194.136719 341.785156 197.597656 341.785156 201.863281 L 341.785156 218.964844 C 341.785156 223.234375 338.324219 226.691406 334.058594 226.691406 Z M 334.058594 226.691406 "
        style="
          stroke: none;
          fill-rule: nonzero;
          fill: rgb(22.745098%, 28.235294%, 29.019608%);
          fill-opacity: 1;
        "
      />
      <path
        d="M 344.394531 247.078125 L 323.722656 247.078125 C 319.457031 247.078125 315.996094 243.617188 315.996094 239.351562 C 315.996094 235.085938 319.457031 231.625 323.722656 231.625 L 344.394531 231.625 C 348.664062 231.625 352.121094 235.085938 352.121094 239.351562 C 352.121094 243.617188 348.664062 247.078125 344.394531 247.078125 Z M 344.394531 247.078125 "
        style="
          stroke: none;
          fill-rule: nonzero;
          fill: rgb(97.647059%, 50.196078%, 51.764706%);
          fill-opacity: 1;
        "
      />
      <path
        d="M 177.9375 226.691406 C 173.671875 226.691406 170.210938 223.234375 170.210938 218.964844 L 170.210938 201.863281 C 170.210938 197.597656 173.671875 194.136719 177.9375 194.136719 C 182.207031 194.136719 185.664062 197.597656 185.664062 201.863281 L 185.664062 218.964844 C 185.664062 223.234375 182.207031 226.691406 177.9375 226.691406 Z M 177.9375 226.691406 "
        style="
          stroke: none;
          fill-rule: nonzero;
          fill: rgb(22.745098%, 28.235294%, 29.019608%);
          fill-opacity: 1;
        "
      />
      <path
        d="M 188.277344 247.078125 L 167.605469 247.078125 C 163.335938 247.078125 159.878906 243.617188 159.878906 239.351562 C 159.878906 235.085938 163.335938 231.625 167.605469 231.625 L 188.277344 231.625 C 192.542969 231.625 196.003906 235.085938 196.003906 239.351562 C 196.003906 243.617188 192.542969 247.078125 188.277344 247.078125 Z M 188.277344 247.078125 "
        style="
          stroke: none;
          fill-rule: nonzero;
          fill: rgb(97.647059%, 50.196078%, 51.764706%);
          fill-opacity: 1;
        "
      />
      <path
        d="M 256 246.222656 C 248.328125 246.222656 241.121094 243.007812 236.21875 237.402344 C 233.410156 234.191406 233.738281 229.308594 236.953125 226.5 C 240.164062 223.691406 245.046875 224.019531 247.855469 227.234375 C 249.820312 229.480469 252.789062 230.769531 256 230.769531 C 259.210938 230.769531 262.179688 229.480469 264.144531 227.234375 C 266.953125 224.019531 271.832031 223.691406 275.046875 226.5 C 278.257812 229.308594 278.585938 234.191406 275.777344 237.402344 C 270.878906 243.007812 263.671875 246.222656 256 246.222656 Z M 256 246.222656 "
        style="
          stroke: none;
          fill-rule: nonzero;
          fill: rgb(22.745098%, 28.235294%, 29.019608%);
          fill-opacity: 1;
        "
      />
    </g>
  </svg>

  <!-- TinyImage -->
  <svg
    v-else-if="pluginName == 'TinyImage'"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <g fill="#f3f5e6">
      <path
        d="M352.17 0H159.83a26.56 26.56 0 00-26.55 26.56v42.3l125.5 33.32 119.95-33.32v-42.3A26.56 26.56 0 00352.17 0zM295.43 179.92l-113.29-49.98-132.16 49.98v301.1A30.98 30.98 0 0080.96 512h214.47l83.3-166.04-83.3-166.04z"
      />
    </g>
    <path
      fill="#d7d9cc"
      d="M378.73 141.05l-83.3 38.87V512h135.61a30.98 30.98 0 0030.98-30.98v-301.1l-83.3-38.87z"
    />
    <path fill="#80e2ff" d="M49.98 269.89h245.45v152.16H49.98z" />
    <g fill="#3b484a">
      <path
        d="M106.52 350.17a7.73 7.73 0 01-7.73-7.73v-11.98a7.73 7.73 0 1115.45 0v11.98a7.73 7.73 0 01-7.72 7.73zM238.9 350.17a7.73 7.73 0 01-7.73-7.73v-11.98a7.73 7.73 0 1115.45 0v11.98a7.73 7.73 0 01-7.73 7.73zM172.7 356.56c-8.6 0-16.67-3.6-22.15-9.87a7.73 7.73 0 1111.64-10.17c2.54 2.92 6.38 4.59 10.51 4.59s7.98-1.67 10.52-4.59a7.72 7.72 0 1111.64 10.17 29.32 29.32 0 01-22.16 9.87z"
      />
    </g>
    <path fill="#55c2e3" d="M378.73 68.86l-83.3 111.06h166.6z" />
    <path fill="#80e2ff" d="M49.98 179.92l83.3-111.06h245.45l-83.3 111.06z" />
    <path fill="#55c2e3" d="M295.43 269.89h166.59v152.16H295.43z" />
  </svg>

  <!-- Weblify -->

  <svg
    v-else-if="pluginName == 'Weblify'"
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <path
      style="fill: #937254"
      d="M420.691,182.618H91.309l52.392,311.073c1.78,10.57,10.932,18.309,21.651,18.309h181.296
	c10.719,0,19.871-7.74,21.651-18.309L420.691,182.618z"
    />
    <g style="opacity: 0.1">
      <path
        d="M91.309,182.617l5.041,29.932h288.505L337.504,493.69c-1.78,10.57-10.932,18.31-21.651,18.31h30.795
		c10.719,0,19.871-7.74,21.651-18.31l52.392-311.073H91.309z"
      />
    </g>
    <g>
      <path
        style="fill: #e4e7e9"
        d="M436.243,182.618H75.757c-8.419,0-15.243-6.825-15.243-15.243V140.18
		c0-8.418,6.824-15.243,15.243-15.243h360.486c8.418,0,15.243,6.825,15.243,15.243v27.195
		C451.486,175.793,444.662,182.618,436.243,182.618z"
      />
      <path
        style="fill: #e4e7e9"
        d="M396.566,74.193H115.434c-10.962,0-19.848,8.886-19.848,19.848v30.896h320.827V94.041
		C416.414,83.079,407.527,74.193,396.566,74.193z"
      />
    </g>
    <g style="opacity: 0.1">
      <path
        d="M436.243,124.937h-33.218c8.418,0,15.243,6.825,15.243,15.243v27.195c0,8.419-6.824,15.243-15.243,15.243h33.218
		c8.418,0,15.243-6.824,15.243-15.243V140.18C451.486,131.761,444.662,124.937,436.243,124.937z"
      />
    </g>
    <path
      style="fill: #e4e7e9"
      d="M93.123,269.567l21.459,127.408c0.585,3.474,3.594,6.019,7.117,6.019h268.603
	c3.523,0,6.532-2.544,7.117-6.019l21.459-127.408c0.742-4.403-2.652-8.416-7.117-8.416H100.24
	C95.775,261.151,92.381,265.164,93.123,269.567z"
    />
    <g style="opacity: 0.1">
      <path
        d="M411.76,261.151h-35.009c4.465,0,7.859,4.013,7.117,8.416l-21.459,127.408c-0.585,3.474-3.594,6.019-7.117,6.019h35.009
		c3.523,0,6.532-2.544,7.117-6.019l21.459-127.408C419.619,265.164,416.225,261.151,411.76,261.151z"
      />
    </g>
    <g style="opacity: 0.1">
      <path
        d="M396.566,74.193h-33.218c10.962,0,19.848,8.886,19.848,19.848v12.235H95.586v18.661h320.827V94.041
		C416.414,83.079,407.527,74.193,396.566,74.193z"
      />
    </g>
    <path
      style="fill: #60d195"
      d="M169.872,0h-9.749c-4.345,0-7.868,3.523-7.868,7.868v66.325h25.484V7.868
	C177.739,3.523,174.217,0,169.872,0z"
    />
    <g style="opacity: 0.1">
      <rect x="152.25" y="57.887" width="25.484" height="16.305" />
    </g>
    <g style="opacity: 0.4">
      <ellipse
        style="fill: #f98f9d"
        cx="309.85"
        cy="347.43"
        rx="24.422"
        ry="14.635"
      />
      <ellipse
        style="fill: #f98f9d"
        cx="200.29"
        cy="347.43"
        rx="24.422"
        ry="14.635"
      />
    </g>
    <g>
      <path
        style="fill: #3e4b58"
        d="M195.716,327.063L195.716,327.063c-4.143,0-7.5-3.358-7.5-7.5v-10.124c0-4.143,3.358-7.5,7.5-7.5
		l0,0c4.142,0,7.5,3.358,7.5,7.5v10.124C203.216,323.705,199.857,327.063,195.716,327.063z"
      />
      <path
        style="fill: #3e4b58"
        d="M314.424,327.063c-4.142,0-7.5-3.357-7.5-7.5v-10.124c0-4.143,3.358-7.5,7.5-7.5s7.5,3.357,7.5,7.5
		v10.124C321.924,323.705,318.566,327.063,314.424,327.063z"
      />
      <path
        style="fill: #3e4b58"
        d="M272.781,327.308c0,9.644-7.989,17.422-17.711,17.076c-9.26-0.329-16.464-8.221-16.464-17.486
		V314.88c0-2.573,2.086-4.659,4.659-4.659h24.857c2.573,0,4.659,2.086,4.659,4.659V327.308z"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
  <svg
    v-else
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="32"
    height="32"
    viewBox="0 0 510 510"
  >
    <linearGradient
      id="e"
      x1="187.262"
      x2="450.422"
      y1="356.61"
      y2="93.451"
      gradientTransform="matrix(1 0 0 -1 0 512)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stop-color="#f8f6fb" />
      <stop offset="1" stop-color="#efdcfb" />
    </linearGradient>
    <linearGradient id="a">
      <stop offset="0" stop-color="#efdcfb" stop-opacity="0" />
      <stop offset=".289" stop-color="#c7b6df" stop-opacity=".289" />
      <stop offset=".592" stop-color="#a495c7" stop-opacity=".592" />
      <stop offset=".84" stop-color="#8e80b7" stop-opacity=".84" />
      <stop offset="1" stop-color="#8679b2" />
    </linearGradient>
    <linearGradient
      xlink:href="#a"
      id="f"
      x1="186.831"
      x2="393.376"
      y1="246.049"
      y2="246.049"
      gradientTransform="matrix(1 0 0 -1 0 512)"
      gradientUnits="userSpaceOnUse"
    />
    <linearGradient
      xlink:href="#a"
      id="g"
      x1="312.918"
      x2="149.875"
      y1="246.049"
      y2="246.049"
      gradientTransform="matrix(1 0 0 -1 0 512)"
      gradientUnits="userSpaceOnUse"
    />
    <linearGradient
      xlink:href="#a"
      id="h"
      x1="344.492"
      x2="507.483"
      y1="202.927"
      y2="47.111"
      gradientTransform="matrix(1 0 0 -1 0 512)"
      gradientUnits="userSpaceOnUse"
    />
    <linearGradient id="b">
      <stop offset="0" stop-color="#ff7044" />
      <stop offset="1" stop-color="#f82814" />
    </linearGradient>
    <linearGradient
      xlink:href="#b"
      id="i"
      x1="133.225"
      x2="133.225"
      y1="459.714"
      y2="367.454"
      gradientTransform="matrix(1 0 0 -1 0 512)"
      gradientUnits="userSpaceOnUse"
    />
    <linearGradient
      xlink:href="#b"
      id="j"
      x1="376.736"
      x2="376.736"
      y1="459.714"
      y2="367.454"
      gradientTransform="matrix(1 0 0 -1 0 512)"
      gradientUnits="userSpaceOnUse"
    />
    <linearGradient id="c">
      <stop offset="0" stop-color="#534854" />
      <stop offset="1" stop-color="#342434" />
    </linearGradient>
    <linearGradient
      xlink:href="#c"
      id="k"
      x1="131.329"
      x2="220.602"
      y1="286.337"
      y2="197.064"
      gradientTransform="matrix(1 0 0 -1 0 512)"
      gradientUnits="userSpaceOnUse"
    />
    <linearGradient
      xlink:href="#c"
      id="l"
      x1="-88.274"
      x2=".999"
      y1="286.338"
      y2="197.064"
      gradientTransform="rotate(180 145.198 256)"
      gradientUnits="userSpaceOnUse"
    />
    <linearGradient
      xlink:href="#b"
      id="m"
      x1="89.704"
      x2="47.077"
      y1="196.396"
      y2="127.246"
      gradientTransform="matrix(1 0 0 -1 0 512)"
      gradientUnits="userSpaceOnUse"
    />
    <linearGradient
      xlink:href="#b"
      id="n"
      x1="121.02"
      x2="79.125"
      y1="170.737"
      y2="102.774"
      gradientTransform="matrix(1 0 0 -1 0 512)"
      gradientUnits="userSpaceOnUse"
    />
    <linearGradient
      xlink:href="#b"
      id="o"
      x1="411.106"
      x2="458.123"
      y1="211.206"
      y2="141.62"
      gradientTransform="matrix(1 0 0 -1 0 512)"
      gradientUnits="userSpaceOnUse"
    />
    <linearGradient
      xlink:href="#b"
      id="p"
      x1="378.878"
      x2="425.675"
      y1="183.111"
      y2="113.851"
      gradientTransform="matrix(1 0 0 -1 0 512)"
      gradientUnits="userSpaceOnUse"
    />
    <linearGradient
      xlink:href="#b"
      id="q"
      x1="179.312"
      x2="330.69"
      y1="177.129"
      y2="25.752"
      gradientTransform="matrix(1 0 0 -1 0 512)"
      gradientUnits="userSpaceOnUse"
    />
    <linearGradient
      xlink:href="#c"
      id="r"
      x1="248.705"
      x2="280.724"
      y1="123.321"
      y2="91.303"
      gradientTransform="matrix(1 0 0 -1 0 512)"
      gradientUnits="userSpaceOnUse"
    />
    <linearGradient
      xlink:href="#b"
      id="s"
      x1="212.97"
      x2="212.97"
      y1="356.691"
      y2="303.179"
      gradientTransform="matrix(1 0 0 -1 0 512)"
      gradientUnits="userSpaceOnUse"
    />
    <linearGradient
      xlink:href="#b"
      id="t"
      x1="44.469"
      x2="44.469"
      y1="356.691"
      y2="303.179"
      gradientTransform="rotate(180 170.75 256)"
      gradientUnits="userSpaceOnUse"
    />
    <linearGradient
      xlink:href="#b"
      id="u"
      x1="192.441"
      x2="192.441"
      y1="369.292"
      y2="335.346"
      gradientTransform="rotate(135 264.761 269.18)"
      gradientUnits="userSpaceOnUse"
    />
    <linearGradient id="d">
      <stop offset="0" stop-color="#f82814" stop-opacity="0" />
      <stop offset=".129" stop-color="#ec2819" stop-opacity=".129" />
      <stop offset=".454" stop-color="#d42724" stop-opacity=".454" />
      <stop offset=".753" stop-color="#c5272b" stop-opacity=".753" />
      <stop offset="1" stop-color="#c0272d" />
    </linearGradient>
    <linearGradient
      xlink:href="#d"
      id="v"
      x1="413.002"
      x2="525.111"
      y1="339.805"
      y2="172.592"
      gradientUnits="userSpaceOnUse"
    />
    <linearGradient
      xlink:href="#d"
      id="w"
      x1="242.869"
      x2="333.874"
      y1="119.942"
      y2="28.937"
      gradientTransform="matrix(1 0 0 -1 0 512)"
      gradientUnits="userSpaceOnUse"
    />
    <path
      fill="url(#e)"
      d="M449.229 207.323C448.585 131.747 435.76.804 383.985.008c-31.442-.483-59.22 39.605-76.68 72.465-16.698-4.167-34.226-6.347-52.305-6.347-18.08 0-35.607 2.18-52.305 6.347C185.236 39.614 157.458-.475 126.015.008c-51.774.796-64.6 131.739-65.244 207.315-6.822 21.849-10.525 45.761-10.525 71.405 0 127.725 91.671 231.267 204.753 231.267s204.753-103.542 204.753-231.267c.002-25.644-3.701-49.556-10.523-71.405z"
    />
    <path
      fill="url(#f)"
      d="M238.086 270.633c0 97.226-37.257 179.429-88.492 206.415-59.523-40.456-99.348-114.14-99.348-198.327 0-25.633 3.706-49.543 10.517-71.393.354-41.378 4.352-99.348 16.422-143.479 11.363-5.858 23.388-8.995 35.843-8.995 69.072 0 125.058 96.611 125.058 215.779z"
    />
    <path
      fill="url(#g)"
      d="M459.754 278.721c0 84.187-39.825 157.871-99.348 198.327-51.235-26.986-88.492-109.189-88.492-206.415 0-119.168 55.986-215.779 125.058-215.779 12.455 0 24.479 3.137 35.843 8.995 12.07 44.131 16.069 102.1 16.422 143.479 6.811 21.85 10.517 45.761 10.517 71.393z"
    />
    <path
      fill="url(#h)"
      d="M459.754 278.721C459.754 406.454 368.079 510 255 510c-60.43 0-114.755-29.569-152.243-76.606 31.599 22.542 69.056 35.597 109.189 35.597 113.079 0 204.754-103.546 204.754-231.263 0-25.648-3.706-49.559-10.517-71.409-.415-49.005-5.951-121.29-23.926-166.282.569-.031 1.153-.046 1.722-.031 51.788.8 64.612 131.746 65.258 207.322 6.811 21.85 10.517 45.761 10.517 71.393z"
    />
    <path
      fill="url(#i)"
      d="M180.239 79.441c-38.318 14.423-71.024 40.025-94.028 74.392 1.738-21.988 4.69-46.837 9.749-69.779 10.564-48.036 24.51-60.891 30.415-60.983h.2c12.547 0 30.399 17.206 47.744 46.053a300.417 300.417 0 0 1 5.92 10.317z"
    />
    <path
      fill="url(#j)"
      d="M423.773 153.802c-23.019-34.382-55.74-59.969-94.074-74.376 16.638-29.969 37.519-56.355 53.726-56.355h.2c5.905.092 19.851 12.947 30.415 60.983 5.043 22.942 8.011 47.76 9.733 69.748z"
    />
    <path
      fill="url(#k)"
      d="M218.594 303.796c-5.054-63.63-55.811-114.387-119.441-119.441-5.196-.413-9.545 3.936-9.132 9.132 5.054 63.63 55.811 114.387 119.441 119.441 5.196.413 9.545-3.936 9.132-9.132z"
    />
    <path
      fill="url(#l)"
      d="M291.405 303.796c5.054-63.63 55.811-114.387 119.441-119.441 5.196-.413 9.545 3.936 9.132 9.132-5.054 63.63-55.811 114.387-119.441 119.441-5.195.413-9.544-3.936-9.132-9.132z"
    />
    <path
      fill="url(#m)"
      d="M138.061 359.863c-11.701-9.41-31.614-23.111-48.436-23.111-1.747 0-3.447.024-5.101.052-16.645.28-30.783-12.13-32.64-28.673a263.628 263.628 0 0 1-1.638-29.41c0-11.686.769-23.019 2.26-33.951 4.798 17.345 16.991 46.606 46.345 59.184 27.908 11.948 38.503 30.384 45.422 51.65 1.215 3.706-3.167 6.689-6.212 4.259z"
    />
    <path
      fill="url(#n)"
      d="M141.475 394.353c-14.208-4.859-37.596-10.948-53.418-5.212l-.118.042c-6.291 2.275-13.285-.635-16.005-6.747a246.106 246.106 0 0 1-12.186-33.889c7.658 3.598 16.268 6.058 25.802 6.612 30.307 1.738 46.56 15.438 60.322 33.09 2.384 3.059-.722 7.365-4.397 6.104z"
    />
    <path
      fill="url(#o)"
      d="M459.754 278.721c0 9.962-.557 19.779-1.638 29.41-1.857 16.543-15.995 28.953-32.64 28.673a299.616 299.616 0 0 0-5.101-.052c-16.822 0-36.735 13.7-48.436 23.111-3.045 2.43-7.427-.554-6.212-4.259 6.919-21.266 17.529-39.702 45.422-51.65 29.354-12.578 41.547-41.84 46.345-59.184 1.491 10.933 2.26 22.265 2.26 33.951z"
    />
    <path
      fill="url(#p)"
      d="M450.251 348.546a245.988 245.988 0 0 1-12.186 33.89c-2.72 6.112-9.713 9.022-16.005 6.747a15.594 15.594 0 0 0-.118-.042c-15.823-5.736-39.21.354-53.418 5.212-3.675 1.261-6.781-3.044-4.398-6.104 13.762-17.652 30.015-31.353 60.322-33.09 9.535-.554 18.146-3.015 25.803-6.613z"
    />
    <path
      fill="url(#q)"
      d="M401.945 406.711a8.457 8.457 0 0 0-11.379-3.682l-25.589 13.078a89.946 89.946 0 0 0-35.911 33.337c-8.636 14.19-24.272 22.194-40.848 20.874-13.884-1.102-24.76-12.875-24.76-26.802v-16.555a8.457 8.457 0 0 0-16.914 0v16.555c0 13.928-10.875 25.7-24.759 26.802-16.559 1.314-32.213-6.683-40.85-20.874a89.929 89.929 0 0 0-35.91-33.337l-25.589-13.078c-4.158-2.125-9.255-.478-11.379 3.682a8.456 8.456 0 0 0 3.682 11.379l25.589 13.078a73.04 73.04 0 0 1 29.16 27.07c11.12 18.269 30.625 29.135 51.74 29.135 1.622 0 3.257-.064 4.895-.194 13.105-1.041 24.557-7.924 31.878-17.938 7.322 10.014 18.773 16.898 31.879 17.938 1.638.13 3.271.194 4.895.194 21.111 0 40.619-10.866 51.74-29.134a73.035 73.035 0 0 1 29.16-27.071l25.589-13.078a8.457 8.457 0 0 0 3.681-11.379z"
    />
    <path
      fill="url(#r)"
      d="M280.763 376.278a49.85 49.85 0 0 0-51.525 0c-12.148 7.333-14.191 24.119-4.158 34.152l15.799 15.799c7.799 7.799 20.445 7.799 28.244 0l15.799-15.799c10.032-10.032 7.988-26.819-4.159-34.152z"
    />
    <path
      fill="url(#s)"
      d="M218.73 153.868c8.11 8.11 30.285 43.433 22.175 51.543s-43.433-14.066-51.543-22.175c-8.11-8.11-8.11-21.258 0-29.367 8.111-8.11 21.259-8.11 29.368-.001z"
    />
    <path
      fill="url(#t)"
      d="M291.27 153.868c-8.11 8.11-30.285 43.433-22.175 51.543s43.433-14.066 51.543-22.175c8.109-8.11 8.109-21.258 0-29.367-8.111-8.11-21.259-8.11-29.368-.001z"
    />
    <path
      fill="url(#u)"
      d="M241.827 149.191c0 7.275 5.898 33.067 13.173 33.067s13.173-25.792 13.173-33.067-5.898-13.173-13.173-13.173-13.173 5.898-13.173 13.173z"
    />
    <path
      fill="url(#v)"
      d="M459.75 278.72c0 10.15-.58 20.14-1.71 29.94-2.08 16.28-16.1 28.42-32.56 28.14-1.66-.02-3.36-.05-5.1-.05-8.75 0-18.32 3.71-27.15 8.6 5.89-12.64 10.77-25.97 14.53-39.86 1.11-.52 2.23-1.04 3.39-1.54 29.31-12.56 41.51-41.75 46.32-59.1h.03c1.48 10.91 2.25 22.21 2.25 33.87z"
    />
    <path
      fill="url(#w)"
      d="M401.945 406.711a8.457 8.457 0 0 0-11.379-3.682l-25.589 13.078a89.946 89.946 0 0 0-35.911 33.337c-8.636 14.19-24.272 22.194-40.849 20.874-7.803-.619-14.654-4.609-19.16-10.457a183.202 183.202 0 0 1-38.459 8.164c-2.73 1.232-5.689 2.045-8.814 2.293a43.958 43.958 0 0 1-15.188-1.437 182.707 182.707 0 0 1-35.921-4.629c11.469 14.607 28.875 23.121 47.552 23.121 1.622 0 3.257-.064 4.895-.194 13.105-1.041 24.557-7.924 31.878-17.938 7.322 10.014 18.773 16.898 31.879 17.938 1.638.13 3.271.194 4.895.194 21.111 0 40.619-10.866 51.74-29.134a73.035 73.035 0 0 1 29.16-27.071l25.589-13.078a8.456 8.456 0 0 0 3.682-11.379z"
    />
  </svg>
</template>
<script>
export default {
  name: "PluginIcon",
  props: {
    pluginName: String
  }
};
</script>
