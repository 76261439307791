<template>
  <div class="login">
    <Matrix />
    <form class="login__form" @submit.prevent="handleLogin">
      <h1 class="login__title">{{ title }}</h1>
      <label class="login__label">Email</label>
      <input
        class="login__input login__input--email"
        type="email"
        v-model="email"
        required
      />
      <label class="login__label">Password</label>
      <input class="login__input" v-model="password" type="password" required />

      <div v-if="error" class="error">
        <p>{{ error }}</p>
      </div>
      <div v-if="deleted" class="error">
        <p>
          Oops! This design wasn't found. The URL may be wrong, or it may have
          been deleted by the owner.
        </p>
      </div>

      <div v-if="resetError" class="error">
        Oops! Please ensure you're using a valid email address which is
        registered as a Hypermatic admin user.
      </div>
      <div v-if="resetSuccess" class="success">
        Sent! Please check your email inbox for <b>{{ resetEmail }}</b> and
        click the link to reset your password.
      </div>

      <button class="login__button" type="submit" :disabled="!password">
        <span v-if="loading">Please wait...</span>
        <span v-else>Login</span>
      </button>
      <button
        class="login__button login__button--secondary"
        type="button"
        :disabled="!email"
        @click="sendPasswordResetEmail"
      >
        Send Password Reset Email
      </button>

      <div v-if="loading" class="login__loader">
        <Spinner class="login__spinner" />
      </div>
    </form>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";
import Matrix from "@/components/Matrix.vue";
import Spinner from "@/components/Spinner.vue";

export default {
  props: {
    title: {
      type: String,
      default: "Login to view designs"
    },
    // email: {
    //   type: String,
    //   default: ""
    // },
    error: {
      type: String,
      default: ""
    },
    deleted: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Matrix,
    Spinner
  },
  data() {
    return {
      resetError: "",
      resetSuccess: "",
      resetEmail: "",
      email: "",
      password: "",
      loading: false
    };
  },
  mounted() {
    document.title = this.title;
    // if (this.qrPassword) {
    //   this.password = this.qrPassword;
    //   setTimeout(() => {
    //     this.handleLogin();
    //   }, 100);
    // }
  },
  computed: {
    // qrPassword() {
    //   return this.decodeBase64Url(this.$route.query.qr);
    // }
  },
  methods: {
    // decodeBase64Url(str) {
    //   return decodeURIComponent(
    //     Array.prototype.map
    //       .call(atob(str), function(c) {
    //         return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
    //       })
    //       .join("")
    //   );
    // },
    sendPasswordResetEmail() {
      this.resetEmail = this.email;
      this.resetSuccess = false;
      this.resetError = false;
      firebase
        .auth()
        .sendPasswordResetEmail(this.email)
        .then(() => {
          this.resetError = false;
          this.resetSuccess = true;
          // console.log("sent!");
          // Password reset email sent!
          // ..
        })
        .catch(() => {
          this.resetSuccess = false;
          this.resetError = true;
          // console.log(error);
          // var errorCode = error.code;
          // var errorMessage = error.message;
          // ..
        });
    },
    handleLogin() {
      this.loading = true;
      firebase
        .auth()
        .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
        .then(() => {
          return firebase
            .auth()
            .signInWithEmailAndPassword(this.email, this.password)
            .then(
              user => {
                this.$emit("auth", user);
                this.loading = false;
              },
              err => {
                this.loading = false;
                this.$emit("error", err);
              }
            );
        })
        .catch(error => {
          this.loading = false;
          this.$emit("error", error);
        });
    }
  }
};
</script>

<style lang="scss">
.login {
  position: fixed;
  background: #fff;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.login__form {
  // @include font-style(body);
  display: block;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 0;
  width: 400px;
  padding: 48px;
  border-radius: 8px;
  background-color: #fff;
  color: #6d6d6d;
  box-shadow: rgba(60, 66, 87, 0.12) 0px 7px 14px 0px,
    rgba(0, 0, 0, 0.12) 0px 3px 6px 0px;
  @media only screen and (max-width: 600px) {
    width: 280px;
    padding: 24px;
  }
}

.login__loader {
  background: rgba(#fff, 0.8);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-content: center;
}

.login__title {
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  margin: 0;
  margin-bottom: 20px;
}

.login__button {
  appearance: none;
  outline: 0;
  border: 0;
  width: 100%;
  color: #fff;
  border-radius: 4px;
  min-height: 44px;
  font-size: 16px;
  font-weight: 500;
  margin-top: 24px;
  cursor: pointer;
  background-color: #2f3d4c;
  box-shadow: 0 2px 4px rgba(#2f3d4c, 0.2);
  transition: all 300ms ease;
  display: block;
  text-align: center;

  &:focus,
  &:hover {
    background-color: lighten(#2f3d4c, 5%);
    box-shadow: 0 4px 6px rgba(#2f3d4c, 0.25);
  }

  &:active {
    background-color: #2f3d4c;
    box-shadow: 0 2px 4px rgba(#2f3d4c, 0.2);
  }

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
    &:hover {
      background-color: #2f3d4c;
      opacity: 0.3;
      cursor: not-allowed;
      box-shadow: 0 2px 4px rgba(#2f3d4c, 0.2);
    }
  }
}

.login__button--secondary {
  background: none;
  box-shadow: none;
  color: #2f3d4c;

  &:focus,
  &:hover {
    background: none;
    // box-shadow: 0 4px 6px rgba(#2f3d4c, 0.25);
  }

  &:active {
    background: none;
    // box-shadow: 0 2px 4px rgba(#2f3d4c, 0.2);
  }

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
    &:hover {
      background: none;
      opacity: 0.3;
      cursor: not-allowed;
      // box-shadow: 0 2px 4px rgba(#2f3d4c, 0.2);
    }
  }
}

.login__label {
  font-size: 14px;
  font-weight: normal;
  text-align: left;
  display: block;
  color: #111;
  margin-bottom: 4px;
  margin-top: 16px;
}

.login__input {
  width: 100%;
  padding: 8px 16px;
  padding-left: 46px;
  border-radius: 4px;
  min-height: 44px;
  appearance: none;
  font-size: 16px;
  line-height: 32px;
  font-weight: 400;
  color: rgb(26, 31, 54);
  outline: 0;
  border: 1px solid #ccc;
  transition: all 300ms ease;
  background-color: rgb(255, 255, 255);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M6 8v-2c0-3.313 2.687-6 6-6 3.312 0 6 2.687 6 6v2h-2v-2c0-2.206-1.795-4-4-4s-4 1.794-4 4v2h-2zm-3 2v14h18v-14h-18z'/%3E%3C/svg%3E") !important;
  background-repeat: no-repeat !important;
  background-size: 16px 16px !important;
  background-position: center left 16px !important;

  &:active,
  &:focus {
    border: 1px solid #555;
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px;
  }
}

.login__input--email {
  background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M0 3v18h24v-18h-24zm6.623 7.929l-4.623 5.712v-9.458l4.623 3.746zm-4.141-5.929h19.035l-9.517 7.713-9.518-7.713zm5.694 7.188l3.824 3.099 3.83-3.104 5.612 6.817h-18.779l5.513-6.812zm9.208-1.264l4.616-3.741v9.348l-4.616-5.607z'/%3E%3C/svg%3E") !important;
}

.success,
.error {
  margin-top: 24px;
  color: #fff;
  background-color: #c72e45;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='white' viewBox='0 0 24 24'%3E%3Cpath d='M12 5.177l8.631 15.823h-17.262l8.631-15.823zm0-4.177l-12 22h24l-12-22zm-1 9h2v6h-2v-6zm1 9.75c-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25 1.25.56 1.25 1.25-.561 1.25-1.25 1.25z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 16px 16px;
  background-position: center left 16px;
  border-radius: 4px;
  padding: 16px;
  padding-left: 48px;
  font-size: 14px;
  font-weight: normal;

  p {
    margin: 0;
  }
}

.success {
  background-color: green;
  background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='white' viewBox='0 0 24 24'%3E%3Cpath d='M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z'/%3E%3C/svg%3E") !important;
}
</style>
